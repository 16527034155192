const badgesList = [
  "javascript",
  "React",
  "node.js",
  "python",
  "Django",
  "reportlab",
  "beautiful_soup",
  "HTML",
  "Css",
  "GCP",
  "functional programming",
  "FHIR",
  "postgres",
  "mongoDB",
  "Mui",
  "bootstrap",
  "Vue",
  "Cypress",
  "integration tests",
  "unitary tests",
  "e2e tests",
];

export { badgesList };
